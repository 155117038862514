<template>
  <div class="building-dynamic">
    <navbar title="楼盘动态" />
    <div class="dynamic-box" v-if="dynamicList && dynamicList.length > 0">
        <div class="dynamic-item" v-for="item in dynamicList" :key="item.id">
            <div class="dynamic-title">{{item.title}}</div>
            <div class="dynamic-content" v-html="$xss(item.content)"></div>
            <div class="dynamic-time">{{item.createTime != null?getTimes.timestampToTime(item.createTime):""}}</div>
        </div>
    </div>
   <van-empty v-else description="暂无动态"/>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
export default {
  name: "buildingDynamic",
  data() {
    return {
      dynamicList: [
      
      ],
      id: "",
    };
  },
  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;
  },



  methods:{
    getHouseNoticeList() {

      const data = {
            //楼盘id
            id: this.id,
          }
        this.$post('home/getHouseNoticeList',data).then((res) => {
          if (res.status == 200) {
            this.dynamicList = res.data.houseNotices
 
          }else {
            this.$toast.fail(res.msg);
          }
        });
    },
  },
  components: {
    navbar,
  },
  mounted() {
    this.getHouseNoticeList();
  },
};
</script>
<style lang="less" scoped>
.building-dynamic{
    .dynamic-box{
        .dynamic-item{
            padding: 0 12px;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            &:last-child{
                border-bottom: 0;
            }
            .dynamic-title{
                line-height: 50px;
                font-size: 17px;
                color: #333;
            }
            .dynamic-content{
                color: #333;
                font-size: 14px;
                text-align: justify;
                img{
                    max-width: 100%;
                }
            }
            .dynamic-time{
                line-height: 40px;
                color: #777;
                font-size: 14px;
            }
        }
    }
}
</style>